export default class DetalhesSaida {
  constructor(
    funcionario = "",
    praca = "",
    vendedor = "",
    cpf = "",
    telefone = "",
    endereco = "",
    numero = "",
    bairro = "",
    cidade = "",
    estado = "",
    dataSaida = "",
    valorTotalMercadoriaComComissao = "",
    valorTotalMercadoriaSemComissao = ""
  ) {
    ;(this.funcionario = funcionario),
      (this.praca = praca),
      (this.vendedor = vendedor),
      (this.cpf = cpf),
      (this.telefone = telefone),
      (this.endereco = endereco),
      (this.numero = numero),
      (this.bairro = bairro),
      (this.cidade = cidade),
      (this.estado = estado),
      (this.dataSaida = dataSaida),
      (this.valorTotalMercadoriaComComissao = valorTotalMercadoriaComComissao),
      (this.valorTotalMercadoriaSemComissao = valorTotalMercadoriaSemComissao)
  }
}

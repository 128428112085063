<template>
  <v-form ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Funcionário"
            v-model="detalhesSaida.funcionario"
            :readonly="true"
          ></v-text-field>
          <v-text-field class="mr-2" label="Praça" v-model="detalhesSaida.praca" :readonly="true"></v-text-field>
          <v-text-field class="mr-2" label="CPF" v-model="detalhesSaida.cpf" :readonly="true"></v-text-field>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Vendedor(a)"
            v-model="detalhesSaida.vendedor"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Data saída"
            v-model="detalhesSaida.dataSaida"
            :readonly="true"
          ></v-text-field>
          <v-menu
            class="pr-2"
            ref="dtReturn"
            lazy
            :close-on-content-click="false"
            v-model="dtPrevReturn"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="dataRetorno"
          >
            <v-text-field
              slot="activator"
              label="Data retorno"
              v-model="dataRetorno"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="dataRetorno" no-title scrollable locale="pt-br">
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dtPrevReturn = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.dtReturn.save(dataRetorno)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field class="mr-2" label="Telefone" v-model="detalhesSaida.telefone" :readonly="true"></v-text-field>
          <v-text-field class="mr-2" label="Endereço" v-model="detalhesSaida.endereco" :readonly="true"></v-text-field>
          <v-text-field class="mr-2" label="Nº" v-model="detalhesSaida.numero" :readonly="true"></v-text-field>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field class="mr-2" label="Bairro" v-model="detalhesSaida.bairro" :readonly="true"></v-text-field>
          <v-text-field class="mr-2" label="Cidade" v-model="detalhesSaida.cidade" :readonly="true"></v-text-field>
          <v-text-field class="mr-2" label="UF" v-model="detalhesSaida.estado" :readonly="true"></v-text-field>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Valor total da sacola (produtos com comissão)"
            v-model="detalhesSaida.valorTotalMercadoriaComComissao"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Valor total da sacola (produtos sem comissão)"
            v-model="detalhesSaida.valorTotalMercadoriaSemComissao"
            :readonly="true"
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field class="mr-2" label="Valor devolvido com comissão" v-model="valorDevolvidoComComissao" :readonly="true"></v-text-field>
          <v-text-field class="mr-2" label="Valor devolvido sem comissão" v-model="valorDevolvidoSemComissao" :readonly="true"></v-text-field>
          <v-text-field class="mr-2" label="Valor vendido com comissão" v-model="valorVendidoComComissao" :readonly="true"></v-text-field>
          <v-text-field class="mr-2" label="Valor vendido sem comissão" v-model="valorVendidoSemComissao" :readonly="true"></v-text-field>
          <v-text-field class="mr-2" label="Valor acerto" v-model="valorAcerto" :readonly="true"></v-text-field>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-data-table :headers="headers" :items="produtos" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td style="display:none;">{{ props.item.IdSaida }}</td>
              <td style="display:none;">{{ props.item.IdProduto }}</td>
              <td>{{ props.item.referencia }}</td>
              <td>{{ props.item.nomeProduto }}</td>
              <td>{{ props.item.quantidade }}</td>
              <td>{{ props.item.semComissao ? 'SIM' : 'NÃO' }}</td>
              <td>{{ props.item.precoVenda }}</td>
              <td>
                <v-text-field
                  style="width:80px"
                  type="number"
                  name="quantidadeDevolvida"
                  v-model="props.item.quantidadeDevolvida"
                  required
                ></v-text-field>
              </td>
              <td>
                {{
                  props.item.quantidadeDevolvida !== undefined
                    ? props.item.quantidade - props.item.quantidadeDevolvida
                    : 0
                }}
              </td>
              <td>
                {{
                  props.item.quantidadeDevolvida !== undefined
                    ? (props.item.quantidade - props.item.quantidadeDevolvida) * props.item.precoVenda
                    : 0
                }}
              </td>
            </template>
          </v-data-table>
        </div>
        <div class="right">
          <v-btn dark color="primary" outline @click="atualizarValores">Atualizar valores</v-btn>
        </div>
      </v-flex>
    </v-layout>
    <div class="form-btn">
      <pagamento></pagamento>
      <v-data-table :headers="headersPagamento" :items="pagamento" hide-actions class="elevation-0">
        <template v-slot:items="props">
          <td style="display:none;">{{ props.item.IdFormaPagamento }}</td>
          <td>{{ props.item.ValorPago }}</td>
          <td>{{ props.item.FormaPagamento }}</td>
          <td>{{ props.item.DataPagamento }}</td>
          <td></td>
        </template>
      </v-data-table>
    </div>
    <div class="form-btn">
      <v-btn outline @click="submit" color="primary">Salvar</v-btn>
      <v-btn outline @click="voltar">Voltar</v-btn>
    </div>
    <div>
      <progress-circular></progress-circular>
    </div>
    <v-snackbar :color="'error'" v-model="snackbar" :top="true">
      {{ text }}
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </v-form>
</template>
<script>
import { serverBus } from "../../main"
import DetalhesSaida from "../../domain/saida/DetalhesSaida"
import PagamentoObj from "../../domain/saida/Pagamento"
import Pagamento from "./pagamento"
import ProgressCircular from "../progressCircular/progressCircular"
import RetornoCommand from "../../domain/saida/RetornoCommand"
import { ServicoSaida } from "../../servicos/servicoSaida"
import { ServicoRetorno } from "../../servicos/servicoRetorno"
const servicoSaida = new ServicoSaida()
const servicoRetorno = new ServicoRetorno()
export default {
  components: {
    Pagamento,
    ProgressCircular
  },
  data() {
    return {
      id: null,
      detalhesSaida: new DetalhesSaida(),
      pagamento: [],
      dtPrevReturn: false,
      dataRetorno: null,
      produtos: [],
      valorDevolvidoComComissao: 0,
      valorDevolvidoSemComissao: 0,
      valorVendidoComComissao: 0,
      valorVendidoSemComissao: 0,
      valorAcerto: 0,
      porcentagemVendedor: 30,
      saldoAtual: null,
      snackbar: false,
      text: "",
      headers: [
        { text: "Referência", align: "left", sortable: false, value: "referencia" },
        { text: "Descrição", align: "left", sortable: false, value: "nomeProduto" },
        { text: "Qtd em saída", align: "left", sortable: false, value: "quantidade" },
        { text: "Sem Comissão", align: "left", sortable: false, value: "semComissa" },
        { text: "Valor unitário", align: "left", sortable: false, value: "precoVenda" },
        { text: "Qtd devolvida", align: "left", sortable: false, value: "quantidadeDevolvida" },
        { text: "Qtd vendida", align: "left", sortable: false, value: "quantidadeVendida" },
        { text: "Total vendido", align: "left", sortable: false, value: "totalVendido" }
      ],
      headersPagamento: [
        { text: "Valor", align: "left", sortable: false, value: "ValorPago" },
        { text: "Forma de pagamento", align: "left", sortable: false, value: "FormaPagamento" },
        { text: "Data", align: "left", sortable: false, value: "DataPagamento" },
        { text: "", align: "left", sortable: false }
      ]
    }
  },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoSaida.buscarDetalhesRetorno(this.id).then(res => {
        this.detalhesSaida = res.data
        this.produtos = res.data.produtosResumidos
        this.calcularValores()
        this.atualizarSaldo(0, false)
      })
    }
    serverBus.$on("adicionaPagamento", value => {
      var novoPagamento = new PagamentoObj()
      novoPagamento.IdFormaPagamento = value.IdFormaPagamento
      novoPagamento.DataPagamento = value.DataPagamento
      novoPagamento.FormaPagamento = value.FormaPagamento
      novoPagamento.ValorPago = value.ValorPago
      novoPagamento.ValorPago = novoPagamento.ValorPago.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      this.pagamento.push(novoPagamento)

      this.atualizarSaldo(parseFloat(novoPagamento.ValorPago.replace("R$", "").replace(",", ".")), false)
    })
  },
  computed: {
    totalVendido() {
      return this.valorVendidoComComissao + this.valorVendidoSemComissao
    }
  },
  methods: {
    submit() {
      this.abrirProgressCircular()
      if (this.$refs.form.validate()) {
        var retornoCommand = new RetornoCommand()
        retornoCommand.IdSaida = parseInt(this.id)
        retornoCommand.DataRetorno = this.dataRetorno
        retornoCommand.IdVendedor = this.detalhesSaida.idVendedor
        retornoCommand.TotalVenda = this.valorVendidoComComissao
        retornoCommand.ValorASerPago = this.valorAcerto
        retornoCommand.TotalPago = 0 ///Calculando no C#
        retornoCommand.PercentualVendedor = this.porcentagemVendedor
        retornoCommand.ProdutosDevolvidos = []
        retornoCommand.Pagamentos = []

        this.pagamento.forEach(element => {
          var pag = new PagamentoObj()
          pag.ValorPago = parseFloat(
            element.ValorPago.toString()
              .replace("R$", "")
              .replace(",", ".")
          )
          pag.IdFormaPagamento = parseInt(element.IdFormaPagamento)
          pag.DataPagamento = this.validarData(element.DataPagamento)

          retornoCommand.Pagamentos.push(pag)
        })

        this.produtos.forEach(element => {
          element.quantidadeDevolvida = parseInt(element.quantidadeDevolvida)
          retornoCommand.ProdutosDevolvidos.push(element)
        })
        
        servicoRetorno.salvar(retornoCommand).then(res => {
          if (res.status === 200 && res.data.length === undefined) {
            this.fecharProgressCircular()
            this.$router.push({ name: "saidaConsignada" })
          } else {
            this.fecharProgressCircular()
            this.snackbar = true
            this.text = res.data[0].title + " - " + res.data[0].detail
          }
        })
      }
    },
    validarData(data) {
      var dataComBarra = data.split("/")
      if (dataComBarra.length > 1) {
        return new Date(dataComBarra[2], dataComBarra[1], dataComBarra[0])
      } else {
        return new Date(data)
      }
    },
    calcularValores() {
      if(this.valorVendidoComComissao === 0) {
        this.valorVendidoComComissao = parseFloat((this.detalhesSaida.valorTotalMercadoriaComComissao - this.valorDevolvidoComComissao).toFixed(2))
      }
      if(this.valorVendidoSemComissao === 0) {
        this.valorVendidoSemComissao = parseFloat((this.detalhesSaida.valorTotalMercadoriaSemComissao - this.valorDevolvidoSemComissao).toFixed(2))
      }

      if (this.valorVendidoComComissao >= parseFloat(1000)) {
        this.porcentagemVendedor = 40
      } else if (this.valorVendidoComComissao >= parseFloat(400)){
        this.porcentagemVendedor = 35
      } else {
        this.porcentagemVendedor = 30
      }
      
      var porcentagem = parseFloat((1 - parseFloat(this.porcentagemVendedor) / 100).toFixed(2))
      this.valorAcerto = parseFloat((this.valorVendidoComComissao * porcentagem).toFixed(2)) + parseFloat((this.valorVendidoSemComissao).toFixed(2))

      this.atualizarSaldo(0, true)
    },
    atualizarSaldo(valorPagamento, atualizadoAcerto) {
      if (this.saldoAtual === null || atualizadoAcerto) {
        this.saldoAtual = this.valorAcerto
      }
      this.saldoAtual = parseFloat((this.saldoAtual - valorPagamento).toFixed(2))
      serverBus.$emit("atualizaSaldo", this.saldoAtual)
    },
    atualizarValores() {
      this.valorVendidoComComissao = 0
      this.valorVendidoSemComissao = 0
      this.produtos.forEach(p => {
        if (p.quantidadeDevolvida) {
          if(p.semComissao === 0) {
            this.valorDevolvidoComComissao = +parseFloat((p.quantidadeDevolvida * p.precoVenda)).toFixed(2) + +parseFloat(this.valorDevolvidoComComissao).toFixed(2)
            this.valorVendidoComComissao = +parseFloat(((p.quantidade - p.quantidadeDevolvida) * p.precoVenda)).toFixed(2) + +parseFloat(this.valorVendidoComComissao).toFixed(2)
          } else {
            this.valorDevolvidoSemComissao = +parseFloat((p.quantidadeDevolvida * p.precoVenda)).toFixed(2) + +parseFloat(this.valorDevolvidoSemComissao).toFixed(2)
            this.valorVendidoSemComissao = +parseFloat(((p.quantidade - p.quantidadeDevolvida) * p.precoVenda)).toFixed(2) + +parseFloat(this.valorVendidoSemComissao).toFixed(2)
          }          
        } else {
          p.quantidadeDevolvida = 0
          if(p.semComissao === 0) {
            this.valorDevolvidoComComissao = +parseFloat((p.quantidadeDevolvida * p.precoVenda)).toFixed(2) + +parseFloat(this.valorDevolvidoComComissao).toFixed(2)
            this.valorVendidoComComissao = +parseFloat(((p.quantidade - p.quantidadeDevolvida) * p.precoVenda)).toFixed(2) + +parseFloat(this.valorVendidoComComissao).toFixed(2)
          } else {
            this.valorDevolvidoSemComissao = +parseFloat((p.quantidadeDevolvida * p.precoVenda)).toFixed(2) + +parseFloat(this.valorDevolvidoSemComissao).toFixed(2)
            this.valorVendidoSemComissao = +parseFloat(((p.quantidade - p.quantidadeDevolvida) * p.precoVenda)).toFixed(2) + +parseFloat(this.valorVendidoComComissao).toFixed(2)
          }
        }
      })
      this.calcularValores()
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    },
    voltar() {
      this.$router.push({ name: "saidaConsignada" })
    }
  }
}
</script>
<style scoped>
a.nounderline:link {
  text-decoration: none;
}
</style>

export default class RetornoCommand {
  constructor(
    idSaida = "",
    dataRetorno = "",
    idVendedor = "",
    totalVenda = "",
    totalPago = "",
    percentualVendedor = "",
    produtosDevolvidos = [],
    pagamentos = []
  ) {
    this.IdSaida = idSaida
    this.DataRetorno = dataRetorno
    this.IdVendedor = idVendedor
    this.TotalVenda = totalVenda
    this.TotalPago = totalPago
    this.PercentualVendedor = percentualVendedor
    this.ProdutosDevolvidos = produtosDevolvidos
    this.Pagamentos = pagamentos
  }
}

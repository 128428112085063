<template>
  <v-form ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field class="mr-2" label="Saldo" v-model="saldo" readonly></v-text-field>
          <v-text-field
            class="mr-2"
            label="Valor pago"
            name="valorPago"
            placeholder="Valor pago"
            v-model="valorPagamento"
            v-currency="{ currency: 'BRL', locale: 'pt-br' }"
            :error-messages="valorMenorQueSaldo() ? [] : ['O valor não pode ser maior que o saldo']"
            required
          ></v-text-field>
          <v-select
            class="mr-2"
            :items="formasPagamento"
            item-value="Id"
            item-text="FormaPagamento"
            label="Forma de pagamento"
            v-model="idFormaPagamento"
            :rules="formaPagamentoRule"
            return-object
          ></v-select>
          <v-menu
            class="pr-2"
            ref="dtReturn"
            lazy
            :close-on-content-click="false"
            v-model="dtPrevPagamento"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-bottom="-22"
            max-width="290px"
            :return-value.sync="dataPagamento"
          >
            <v-text-field
              slot="activator"
              label="Data pagamento"
              v-model="dataPagamento"
              :rules="dataPagamentoRules"
              append-icon="event"
              readonly
            ></v-text-field>
            <v-date-picker v-model="dataPagamento" no-title scrollable locale="pt-br">
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dtPrevPagamento = false">Cancel</v-btn>
              <v-btn flat color="primary" @click="$refs.dtReturn.save(dataPagamento)">OK</v-btn>
            </v-date-picker>
          </v-menu>
          <v-btn depressed icon dark color="primary" small @click="adicionarPagamento">
            <v-icon small>library_add</v-icon>
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import { VMoney } from "v-money"
import { serverBus } from "../../main"
import FormaPagamento from "../../domain/saida/FormaPagamento"
import Pagamento from "../../domain/saida/Pagamento"
export default {
  data: () => ({
    formasPagamento: [],
    dtPrevPagamento: false,
    dataPagamento: null,
    formaPagamentoRule: [v => !!v || "Campo obrigatório"],
    dataPagamentoRules: [v => !!v || "Campo obrigatório"],
    saldo: 0,
    pagamento: new Pagamento(),
    valorPagamento: null,
    idFormaPagamento: null,
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: " #",
      precision: 2,
      masked: false
    }
  }),
  directives: { money: VMoney },
  created() {
    this.getFormasPagamento()
    serverBus.$on("atualizaSaldo", value => {
      this.valorPagamento = 0
      this.saldo = value
    })
  },
  methods: {
    adicionarPagamento() {
      this.pagamento.ValorPago = parseFloat(this.valorPagamento.replace("R$", "").replace(",", "."))
      this.pagamento.IdFormaPagamento = parseInt(this.idFormaPagamento.Id)
      this.pagamento.FormaPagamento = this.idFormaPagamento.FormaPagamento
      this.pagamento.DataPagamento = this.dataPagamento
      serverBus.$emit("adicionaPagamento", this.pagamento)
    },
    getFormasPagamento() {
      var dinheiro = new FormaPagamento()
      dinheiro.Id = 1
      dinheiro.FormaPagamento = "Dinheiro"
      var cartao = new FormaPagamento()
      cartao.Id = 2
      cartao.FormaPagamento = "Cartão"
      var deposito = new FormaPagamento()
      deposito.Id = 3
      deposito.FormaPagamento = "Depósito"
      var transf = new FormaPagamento()
      transf.Id = 4
      transf.FormaPagamento = "Transferência"
      var cheque = new FormaPagamento()
      cheque.Id = 5
      cheque.FormaPagamento = "Cheque"
      var pix = new FormaPagamento()
      pix.Id = 6
      pix.FormaPagamento = "PIX"
      this.formasPagamento.push(dinheiro)
      this.formasPagamento.push(cartao)
      this.formasPagamento.push(deposito)
      this.formasPagamento.push(transf)
      this.formasPagamento.push(cheque)
      this.formasPagamento.push(pix)
    },
    valorMenorQueSaldo() {
      if (this.valorPagamento !== null) {
        var valor = +parseFloat(this.valorPagamento.toString().replace("R$", "").replace(",",".")).toFixed(2)
        if (valor <= this.saldo) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }
  }
}
</script>
